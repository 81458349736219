export class Detail {

    model: {
        guid: string;
        id: number;
        sysStartTime: Date;
        sysEndTime: Date;
        type: string;
        results: [];
    };

    gridDataset = [];

    bind(bindingContext, overrideContext) {
        if (overrideContext && overrideContext.parentOverrideContext && overrideContext.parentOverrideContext.bindingContext && overrideContext.parentOverrideContext.bindingContext.model) {
            return this.bindReferences(overrideContext.parentOverrideContext.bindingContext);
        }
    }

    async bindReferences(data) {
        if (data) {
            this.model = data.model;
            this.gridDataset = this.model.results.map((e) => e);
        }
    }
}