import { BootstrapValidationController } from '@resources/renderers/bootstrap-form-renderer';
import { GenericEntity } from '@resources/utils/generic-entity';
import { autoinject, BindingEngine, computedFrom, Disposable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { isGuid } from '@dts/scriptlib';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';
import { careHomePatientChanged } from 'services/state/actions';
import { Validator } from 'aurelia-validation';

@autoinject()
export class Index extends GenericEntity {

    allergies: any[] = [];
    allergyToAdd: string;
    selectedAllergies: number[] = [];

    medicalConditions: any[] = [];
    medicalConditionToAdd: string;
    selectedMedicalConditions: number[] = [];

    subscriptions: Disposable[] = [];

    constructor(repository: ImpiloRepository, private readonly controller: BootstrapValidationController, validator: Validator, private readonly router: Router, private readonly bindingEngine: BindingEngine) {
        super(repository, validator);
        this.entity = new ImpiloDb.Tables.Patient();
    }

    bind() {
        return this.controller.validate();
    }

    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.entity = await (this.repository as ImpiloRepository).getPatient(params.guid, false);

            const patient = this.entity as ImpiloDb.Tables.Patient;
            queueMicrotask(() => careHomePatientChanged(this.displayName, patient));

            if (patient.allergies?.length > 0) {
                this.allergies = patient.allergies.split('|').map((item, index) => {
                    if (item.length > 0) {
                        return { id: index, name: item };
                    }
                });
            }

            this.subscriptions.push(this.bindingEngine.collectionObserver(this.allergies)
                .subscribe((splices) => {
                    if (splices.length > 0) {
                        patient.allergies = '';
                        this.allergies.forEach((item, index) => {
                            if (item) {
                                patient.allergies += item.name.trimLeft().trimRight() + (index < this.allergies.length - 1 ? '|' : '');
                            }
                        });
                    }
                }));

            if (patient.medicalConditions?.length > 0) {
                this.medicalConditions = patient.medicalConditions.split('|').map((item, index) => {
                    if (item.length > 0) {
                        return { id: index, name: item };
                    }
                });
            }

            this.subscriptions.push(this.bindingEngine.collectionObserver(this.medicalConditions)
                .subscribe((splices) => {
                    if (splices.length > 0) {
                        patient.medicalConditions = '';
                        this.medicalConditions.forEach((item, index) => {
                            if (item) {
                                patient.medicalConditions += item.name.trimLeft().trimRight() + (index < this.medicalConditions.length - 1 ? '|' : '');
                            }
                        });
                    }
                }));
        }
    }

    // abstract deactivate();
    deactivate() {
        this.subscriptions.forEach(element => {
            element.dispose();
        });

        this.subscriptions.splice(0);
    }

    // abstract escape();
    escape() {
        this.router.navigateToRoute('patients');
    }

    async apply() {
        if (this.canApply) {
            await super.apply();
        }
        this.router.navigateToRoute('medications');
    }

    // abstract get displayName();
    @computedFrom('entity.firstName', 'entity.surname')
    get displayName() {
        const patient = this.entity as ImpiloDb.Tables.Patient;
        const displayName = `Clinical Details for ${patient.firstName} ${patient.surname}`;
        queueMicrotask(() => careHomePatientChanged(displayName, patient));
        return displayName;
    }

    addAllergy() {

        if (this.allergyToAdd && this.allergyToAdd.length > 0) {
            const item = this.allergyToAdd.trimRight().trimLeft();
            if (item.length > 0) {
                const index = this.allergies.findIndex(x => x.name === item);

                if (index == -1) {
                    this.allergies.push({ id: this.allergies.length * 2 + 1, name: item });
                    this.allergyToAdd = '';
                }
            }
        }
    }

    removeSelectedAllergies() {
        this.selectedAllergies.forEach((value) => {
            const index = this.allergies.findIndex(x => x.id === value);
            this.allergies.splice(index, 1);
        });
    }

    sortAllergies() {
        const arr = this.sortArray([...this.allergies]);

        while (this.allergies.length > 0) {
            this.allergies.pop();
        }

        arr.forEach((value) => {
            this.allergies.push(value);
        });

        const patient = (this.entity as ImpiloDb.Tables.Patient);
        patient.allergies = '';
        this.allergies.forEach((value, index) => {
            if (value) {
                patient.allergies += value.name + (index < this.allergies.length - 1 ? '|' : '');
            }
        });
    }

    addMedicalCondition() {

        if (this.medicalConditionToAdd && this.medicalConditionToAdd.length > 0) {
            const item = this.medicalConditionToAdd.trimRight().trimLeft();
            if (item.length > 0) {
                const index = this.medicalConditions.findIndex(x => x.name === item);

                if (index == -1) {
                    this.medicalConditions.push({ id: this.medicalConditions.length * 2 + 1, name: item });
                    this.medicalConditionToAdd = '';
                }
            }
        }
    }

    removeSelectedMedicalConditions() {
        this.selectedMedicalConditions.forEach((value) => {
            const index = this.medicalConditions.findIndex(x => x.id === value);
            this.medicalConditions.splice(index, 1);
        });
    }

    sortMedicalConditions() {
        const arr = this.sortArray([...this.medicalConditions]);

        while (this.medicalConditions.length > 0) {
            this.medicalConditions.pop();
        }

        arr.forEach((value) => {
            this.medicalConditions.push(value);
        });

        const patient = (this.entity as ImpiloDb.Tables.Patient);
        patient.medicalConditions = '';
        this.medicalConditions.forEach((value, index) => {
            if (value) {
                patient.medicalConditions += value.name + (index < this.medicalConditions.length - 1 ? '|' : '');
            }
        });
    }

    sortArray(arr: any[]) {
        arr.sort((a: any, b: any) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        return arr;
    }
}
