import { isGuid } from "@dts/scriptlib";
import { ChartData } from "chart.js";
import { autoinject } from 'aurelia-framework';
import { ImpiloDb, ImpiloRepository } from 'services/impilo-repository';

@autoinject()
export class Dashboard {

    patient: ImpiloDb.Tables.Patient;

    bloodPressureData: ChartData;
    weightData: ChartData;

    bloodGlucoseData: ChartData;
    temperatureData: ChartData;
    bloodOxygenData: ChartData;
    heartRateData: ChartData;
    triglycerideData: ChartData;

    constructor(private readonly repository: ImpiloRepository) {
    }

    // abstract activate(params, routeConfig, navigationInstruction);
    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.patient = await (this.repository as ImpiloRepository).getPatient(params.guid, false);
            const response = await this.repository.getPatientMeasurement(null, this.patient.guid);

            const data = response.results;

            this.resetBloodPressureData(data.filter(f => f.measurementType === 'Blood Pressure'));
            this.resetWeightData(data.filter(f => f.measurementType === 'Weight'));
            this.resetHeartRateData(data.filter(f => f.measurementType === 'Heart Rate'));
            this.resetBloodOxygenData(data.filter(f => f.measurementType === 'Blood Oxygen Level'));
            this.resetBloodGlucoseData(data.filter(f => f.measurementType === 'Blood Glucose'));
            this.resetTemperatureData(data.filter(f => f.measurementType === 'Temperature'));
        }
    }

    resetBloodPressureData(biometricData = []) {
        this.bloodPressureData = {
            labels: biometricData.filter(f => f.key === 'Diastolic').map((item: ImpiloDb.Tables.PatientMeasurement) => {
                return new Date(item.capturedAt).toLocaleDateString('en-ZA', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  });
            }),
            datasets: [
                {
                    label: 'Diastolic',
                    backgroundColor: 'rgba(151,187,205,0.2)',
                    borderColor: 'rgba(151,187,205,1)',
                    pointBackgroundColor: 'rgba(151,187,205,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(151,187,205,1)',
                    data: biometricData.filter(f => f.key === 'Diastolic').map((item: ImpiloDb.Tables.PatientMeasurement) => {
                        return parseFloat(item.value);
                    }),
                    cubicInterpolationMode: 'default',
                    tension: 0.4
                },
                {
                    label: 'Systolic',
                    backgroundColor: 'rgba(220,220,220,0.2)',
                    borderColor: 'rgba(220,220,220,1)',
                    pointBackgroundColor: 'rgba(220,220,220,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    data: biometricData.filter(f => f.key === 'Systolic').map((item: ImpiloDb.Tables.PatientMeasurement) => {
                        return parseFloat(item.value);
                    }),
                    cubicInterpolationMode: 'default',
                    tension: 0.4
                }
            ]
        }
    }

    resetWeightData(biometricData = []) {
        this.weightData = {
            labels: biometricData.map((item: ImpiloDb.Tables.PatientMeasurement) => {
                return new Date(item.capturedAt).toLocaleDateString('en-ZA', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  });
            }),
            datasets: [
                {
                    label: 'Weight',
                    backgroundColor: 'rgba(151,187,205,0.2)',
                    borderColor: 'rgba(151,187,205,1)',
                    pointBackgroundColor: 'rgba(151,187,205,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(151,187,205,1)',
                    data: biometricData.map((item: ImpiloDb.Tables.PatientMeasurement) => {
                        return parseFloat(item.value);
                    }),
                    cubicInterpolationMode: 'default',
                    tension: 0.4
                }
            ]
        }
    }

    resetHeartRateData(biometricData = []) {
        this.heartRateData = {
            labels: biometricData.map((item: ImpiloDb.Tables.PatientMeasurement) => {
                return new Date(item.capturedAt).toLocaleDateString('en-ZA', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  });
            }),
            datasets: [
                {
                    label: 'Heart Rate',
                    backgroundColor: 'rgba(151,187,205,0.2)',
                    borderColor: 'rgba(151,187,205,1)',
                    pointBackgroundColor: 'rgba(151,187,205,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(151,187,205,1)',
                    data: biometricData.map((item: ImpiloDb.Tables.PatientMeasurement) => {
                        return parseFloat(item.value);
                    }),
                    cubicInterpolationMode: 'default',
                    tension: 0.4
                }
            ]
        }
    }

    resetBloodOxygenData(biometricData = []) {
        this.bloodOxygenData = {
            labels: biometricData.map((item: ImpiloDb.Tables.PatientMeasurement) => {
                return new Date(item.capturedAt).toLocaleDateString('en-ZA', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  });
            }),
            datasets: [
                {
                    label: 'Blood O2 Level',
                    backgroundColor: 'rgba(151,187,205,0.2)',
                    borderColor: 'rgba(151,187,205,1)',
                    pointBackgroundColor: 'rgba(151,187,205,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(151,187,205,1)',
                    data: biometricData.map((item: ImpiloDb.Tables.PatientMeasurement) => {
                        return parseFloat(item.value);
                    }),
                    cubicInterpolationMode: 'default',
                    tension: 0.4
                }
            ]
        }
    }

    resetBloodGlucoseData(biometricData = []) {
        this.bloodGlucoseData = {
            labels: biometricData.map((item: ImpiloDb.Tables.PatientMeasurement) => {
                return new Date(item.capturedAt).toLocaleDateString('en-ZA', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  });
            }),
            datasets: [
                {
                    label: 'Blood Glucose',
                    backgroundColor: 'rgba(151,187,205,0.2)',
                    borderColor: 'rgba(151,187,205,1)',
                    pointBackgroundColor: 'rgba(151,187,205,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(151,187,205,1)',
                    data: biometricData.map((item: ImpiloDb.Tables.PatientMeasurement) => {
                        return parseFloat(item.value);
                    }),
                    cubicInterpolationMode: 'default',
                    tension: 0.4
                }
            ]
        }
    }

    resetTemperatureData(biometricData = []) {
        this.temperatureData = {
            labels: biometricData.map((item: ImpiloDb.Tables.PatientMeasurement) => {
                return new Date(item.capturedAt).toLocaleDateString('en-ZA', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  });
            }),
            datasets: [
                {
                    label: 'Temperature',
                    backgroundColor: 'rgba(151,187,205,0.2)',
                    borderColor: 'rgba(151,187,205,1)',
                    pointBackgroundColor: 'rgba(151,187,205,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(151,187,205,1)',
                    data: biometricData.map((item: ImpiloDb.Tables.PatientMeasurement) => {
                        return parseFloat(item.value);
                    }),
                    cubicInterpolationMode: 'default',
                    tension: 0.4
                }
            ]
        }
    }
}