import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';

@autoinject()
export class Index {
    private router: Router;

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.title = '';
        config.map([
            { route: '', redirect: 'search' },
            { route: 'search', name: 'search', moduleId: PLATFORM.moduleName('./search/index'), title: 'Search', nav: true },
            { route: 'patient/:guid?', name: 'patient', moduleId: PLATFORM.moduleName('./patient/index'), title: 'Patient' },
        ]);
        this.router = router;
    }
}
