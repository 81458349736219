import { isGuid } from '@dts/scriptlib';
import { GenericEntitySearch } from '@resources/utils/generic-entity-search';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Formatters, OnEventArgs } from 'aurelia-slickgrid';
import { IEntity, ResultPager } from 'plugins/data-models';
import { AuthService } from 'services/auth-service';
import { ImpiloDb, ImpiloRepository } from 'services/repositories';

@autoinject()
export class Index extends GenericEntitySearch {
    private patient: Partial<ImpiloDb.Tables.Patient> = new ImpiloDb.Tables.Patient();

    constructor(private readonly repository: ImpiloRepository, private readonly router: Router, private readonly authService: AuthService) {
        super();

        this.repository.setMapping(ImpiloDb.ProcedureEnum.spgPatientMedication, ImpiloDb.Tables.PatientMedication.map);

        this.pager = new ResultPager(async (page: number, size: number) => {

            // const currentUser = this.authService.currentUser();

            const filter = this.filter.trim();

            if (filter.length >= 0) {

                this.isWaiting = true;
                const response = await this.repository.getPatientMeasurement(null, this.patient.guid);
                this.isWaiting = false;

                this.setSelectedRow(undefined);

                this.gridDataset = response.results.map((entity: ImpiloDb.Tables.PatientMeasurement) => {
                    return entity;
                });

                return response;
            }
        });

        this.pager.itemsPerPage = 200;

    }

    async attachedDone() {
        if (this.pager) {
            await this.pager.gotoFirst();
        }
    }

    async activate(params, routeConfig, navigationInstruction) {
        if (isGuid(params.guid)) {
            this.patient = await (this.repository).getPatient(params.guid, false);
        }
    }

    defineGrid() {
        this.gridColumns = [
            { id: 'captured', name: 'Captured', field: 'capturedAt', sortable: true, formatter: Formatters.dateIso },
            { id: 'measurementType', name: 'Measurement', field: 'measurementType', sortable: false },
            { id: 'key', name: 'Key', field: 'key', sortable: false },
            { id: 'value', name: 'Value', field: 'value', sortable: false },
        ];

        this.gridOptions = {
            autoResize: {
                containerId: 'container-grid',
                calculateAvailableSizeBy: 'window',
                bottomPadding: 65
            },
            enableGridMenu: false,
            enableCellNavigation: true,
            enableRowSelection: true,
            checkboxSelector: {
                hideSelectAllCheckbox: true
            },
            rowSelectionOptions: {
                selectActiveRow: true,
            },
            enableGrouping: true,
        };
    }

    // abstract editEntity(entity: IEntity): void;
    editEntity(entity: IEntity) {
        // throw new Error('Method not implemented.');
    }

    // abstract newEntity(): void;
    newEntity() {
        // throw new Error('Method not implemented.');
    }

    // abstract handleRowSelection(event, args);
    handleRowSelection(event: any, args: any) {
        // throw new Error('Method not implemented.');
    }



}
